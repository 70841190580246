<template>
  <page-container>
    <a-row :gutter="24" type="flex">
      <a-col v-bind="{ xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }" style="padding-right: 0">
        <div class="ant-pro-table">
          <div class="ant-pro-table-search">
            <search-render
              ref="config_render"
              :form="search_config"
              :model="search_config.model"
              :options="search_config_options"
              :validateInfos="searchValidateInfos"
              @search="search"
            />
          </div>
          <a-card :body-style="{ padding: 0 }" ref="elRef">
            <div class="ant-pro-table-list-toolbar">
              <div class="ant-pro-table-list-toolbar-container">
                <div class="ant-pro-table-list-toolbar-left">
                  <div class="ant-pro-table-list-toolbar-title">汇款凭证</div>
                </div>
                <div class="ant-pro-table-list-toolbar-right">
                  <a-space align="center">
                    <a-button
                      size="small"
                      type="primary"
                      @click="
                        () => {
                          editModal.visible = true;
                          editModal.title = '新建汇款凭证';
                          editModal.form.model = {};
                          editModal.aim_org_id = search_modelRef.aim_org_id;
                        }
                      "
                    >
                      <plus-outlined />
                      新建汇款凭证
                    </a-button>
                    <div class="ant-pro-table-list-toolbar-setting-item">
                      <a-tooltip title="表格斑马纹">
                        <a-switch
                          checked-children="开"
                          un-checked-children="关"
                          v-model:checked="state.stripe"
                        />
                      </a-tooltip>
                    </div>
                  </a-space>
                  <div class="ant-pro-table-list-toolbar-divider">
                    <a-divider type="vertical" />
                  </div>
                  <div class="ant-pro-table-list-toolbar-setting-item">
                    <a-tooltip title="刷新">
                      <reload-outlined @click="handleTableChange" />
                    </a-tooltip>
                  </div>
                  <div class="ant-pro-table-list-toolbar-setting-item">
                    <a-tooltip title="密度">
                      <a-dropdown :trigger="['click']" placement="bottomRight">
                        <column-height-outlined />
                        <template #overlay>
                          <a-menu
                            style="width: 80px"
                            :selected-keys="[state.tableSize]"
                            @click="
                              ({ key }) => {
                                state.tableSize = key;
                              }
                            "
                          >
                            <a-menu-item key="default">
                              <a href="javascript:">默认</a>
                            </a-menu-item>
                            <a-menu-item key="middle">
                              <a href="javascript:">中等</a>
                            </a-menu-item>
                            <a-menu-item key="small">
                              <a href="javascript:">紧凑</a>
                            </a-menu-item>
                          </a-menu>
                        </template>
                      </a-dropdown>
                    </a-tooltip>
                  </div>
                  <div class="ant-pro-table-list-toolbar-setting-item">
                    <a-popover
                      placement="bottomRight"
                      arrowPointAtCenter
                      trigger="click"
                      overlayClassName="ant-pro-table-column-setting-overlay"
                    >
                      <template #title>
                        <div class="ant-pro-table-column-setting-title">
                          <a-checkbox
                            v-model:checked="userColumnState.checkAll"
                            :indeterminate="userColumnState.indeterminate"
                            @change="handleColumnAllClick"
                          >
                            列展示
                          </a-checkbox>
                          <a @click="reset">重置</a>
                        </div>
                      </template>
                      <template #content>
                        <span class="ant-pro-table-column-setting-list">
                          <drag-container
                            lockAxis="y"
                            dragClass="ant-pro-table-drag-ghost"
                            dropClass="ant-pro-table-drop-ghost"
                            @drop="({ removedIndex, addedIndex }) => move(removedIndex, addedIndex)"
                          >
                            <draggable :key="column.key" v-for="column in dynamicColumnItems">
                              <div class="ant-pro-table-column-setting-list-item">
                                <drag-icon />
                                <a-checkbox
                                  :checked="column.checked"
                                  @change="handleColumnChange($event, column)"
                                >
                                  {{ column.label }}
                                </a-checkbox>
                              </div>
                            </draggable>
                          </drag-container>
                        </span>
                      </template>
                      <a-tooltip title="列设置">
                        <setting-outlined />
                      </a-tooltip>
                    </a-popover>
                  </div>
                  <div class="ant-pro-table-list-toolbar-setting-item">
                    <a-tooltip :title="screenState ? '退出全屏' : '全屏'">
                      <fullscreen-outlined v-if="!screenState" @click="setFull" />
                      <fullscreen-exit-outlined v-else @click="exitFull" />
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <a-table
              :scroll="{ y: tableSize.y }"
              :customRow="
                record => {
                  return {
                    onClick: event => {
                      state.currentRow = record;
                      setUserPageInfo({
                        current: 1,
                        ...user_search_modelRef,
                        remittance_set_id: record.id,
                      });
                      userReload();
                    },
                  };
                }
              "
              :size="state.tableSize"
              :loading="state.loading"
              :columns="dynamicColumns"
              :data-source="state.dataSource"
              :rowClassName="setRowClassName"
              :pagination="{
                current: state.current,
                pageSize: state.pageSize,
                total: state.total,
              }"
              @change="handleTableChange"
            >
              <template #index="{ index }">
                <span>{{ index + 1 + state.pageSize * (state.current - 1) }}</span>
              </template>
              <template #status="{ text }">
                <a-tag :color="statusMap[text].status">
                  {{ statusMap[text].text }}
                </a-tag>
              </template>
              <template #datetime="{ text }">
                {{ getDateTime(text) }}
              </template>
              <template #action="{ record }">
                <a-button
                  size="small"
                  type="primary"
                  @click.stop="() => handleOpenEdit(record)"
                  v-if="record.is_active"
                >
                  修改
                </a-button>
                <a-popconfirm v-if="record.is_active" @confirm.stop="() => handleDelete(record)">
                  <template #title>
                    <p>是否确认删除?</p>
                  </template>
                  <a-button size="small" type="danger" @click.stop="() => {}">删除</a-button>
                </a-popconfirm>
                <a-button
                  size="small"
                  type="danger"
                  @click.stop="() => handleRecover(record)"
                  v-if="!record.is_active"
                >
                  恢复
                </a-button>
              </template>
            </a-table>
          </a-card>
        </div>
      </a-col>
      <!--user-->
      <a-col v-bind="{ xs: 24, sm: 12, md: 12, lg: 12, xl: 12 }">
        <div class="ant-pro-table-search">
          <search-render
            ref="user_config_render"
            :form="user_search_config"
            :model="user_search_config.model"
            :options="user_search_config_options"
            :validateInfos="userSearchValidateInfos"
            @search="userSearch"
          />
        </div>
        <a-card :body-style="{ padding: 0 }" ref="rightElRef">
          <div class="ant-pro-table-list-toolbar">
            <div class="ant-pro-table-list-toolbar-container">
              <div class="ant-pro-table-list-toolbar-left">
                <div class="ant-pro-table-list-toolbar-title">汇款凭证项</div>
              </div>
              <div class="ant-pro-table-list-toolbar-right">
                <a-space align="center">
                  <a-button
                    size="small"
                    type="primary"
                    @click="
                      () => {
                        edituserModal.visible = true;
                        edituserModal.title = '新建汇款凭证项';
                        edituserModal.form.model = {};
                      }
                    "
                  >
                    <plus-outlined />
                    新建
                  </a-button>
                  <div class="ant-pro-table-list-toolbar-setting-item">
                    <a-tooltip title="表格斑马纹">
                      <a-switch
                        checked-children="开"
                        un-checked-children="关"
                        v-model:checked="userState.stripe"
                      />
                    </a-tooltip>
                  </div>
                </a-space>
                <div class="ant-pro-table-list-toolbar-divider">
                  <a-divider type="vertical" />
                </div>
                <div class="ant-pro-table-list-toolbar-setting-item">
                  <a-tooltip title="刷新">
                    <reload-outlined @click="handleUserTableChange" />
                  </a-tooltip>
                </div>
                <div class="ant-pro-table-list-toolbar-setting-item">
                  <a-tooltip title="密度">
                    <a-dropdown :trigger="['click']" placement="bottomRight">
                      <column-height-outlined />
                      <template #overlay>
                        <a-menu
                          style="width: 80px"
                          :selected-keys="[userState.tableSize]"
                          @click="
                            ({ key }) => {
                              userState.tableSize = key;
                            }
                          "
                        >
                          <a-menu-item key="default">
                            <a href="javascript:">默认</a>
                          </a-menu-item>
                          <a-menu-item key="middle">
                            <a href="javascript:">中等</a>
                          </a-menu-item>
                          <a-menu-item key="small">
                            <a href="javascript:">紧凑</a>
                          </a-menu-item>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </a-tooltip>
                </div>
                <div class="ant-pro-table-list-toolbar-setting-item">
                  <a-popover
                    placement="bottomRight"
                    arrowPointAtCenter
                    trigger="click"
                    overlayClassName="ant-pro-table-column-setting-overlay"
                  >
                    <template #title>
                      <div class="ant-pro-table-column-setting-title">
                        <a-checkbox
                          v-model:checked="userColumnState.checkAll"
                          :indeterminate="userColumnState.indeterminate"
                          @change="userHandleColumnChange"
                        >
                          列展示
                        </a-checkbox>
                        <a @click="userReset">重置</a>
                      </div>
                    </template>
                    <template #content>
                      <span class="ant-pro-table-column-setting-list">
                        <drag-container
                          lockAxis="y"
                          dragClass="ant-pro-table-drag-ghost"
                          dropClass="ant-pro-table-drop-ghost"
                          @drop="
                            ({ removedIndex, addedIndex }) => userMove(removedIndex, addedIndex)
                          "
                        >
                          <draggable :key="column.key" v-for="column in userDynamicColumnItems">
                            <div class="ant-pro-table-column-setting-list-item">
                              <drag-icon />
                              <a-checkbox
                                :checked="column.checked"
                                @change="userHandleColumnChange($event, column)"
                              >
                                {{ column.label }}
                              </a-checkbox>
                            </div>
                          </draggable>
                        </drag-container>
                      </span>
                    </template>
                    <a-tooltip title="列设置">
                      <setting-outlined />
                    </a-tooltip>
                  </a-popover>
                </div>
                <div class="ant-pro-table-list-toolbar-setting-item">
                  <a-tooltip :title="userScreenState ? '退出全屏' : '全屏'">
                    <fullscreen-outlined v-if="!userScreenState" @click="setUserFull" />
                    <fullscreen-exit-outlined v-else @click="exitUserFull" />
                  </a-tooltip>
                </div>
              </div>
            </div>
          </div>
          <a-table
            :scroll="{ y: tableSize.y }"
            :row-class-name="userStripe"
            :size="userState.tableSize"
            :loading="userState.loading"
            :columns="userDynamicColumns"
            :data-source="userState.dataSource"
            :pagination="{
              current: userState.current,
              pageSize: userState.pageSize,
              total: userState.total,
            }"
            @change="handleUserTableChange"
          >
            <template #index="{ index }">
              <span>{{ index + 1 + userState.pageSize * (userState.current - 1) }}</span>
            </template>
            <template #status="{ text }">
              <a-tag :color="statusMap[text].status">
                {{ statusMap[text].text }}
              </a-tag>
            </template>
            <template #group_list="{ text }">
              <a-tag color="green" v-for="item in text" :key="item.id">
                {{ item.name }}
              </a-tag>
            </template>
            <template #action="{ record }">
              <a-button
                size="small"
                type="primary"
                @click.stop="() => handleuserOpenEdit(record)"
                v-if="record.is_active"
              >
                修改
              </a-button>
              <a-popconfirm v-if="record.is_active" @confirm.stop="() => handleDelete(record)">
                <template #title>
                  <p>是否确认删除?</p>
                </template>
                <a-button size="small" type="danger" @click.stop="() => {}">删除</a-button>
              </a-popconfirm>
              <a-button
                size="small"
                type="danger"
                @click.stop="() => handleRecover(record)"
                v-if="!record.is_active"
              >
                恢复
              </a-button>
            </template>
          </a-table>
        </a-card>
      </a-col>
    </a-row>
    <form-modal
      v-bind="editModal"
      v-if="editModal.visible"
      @cancel="
        () => {
          editModal.visible = false;
        }
      "
      @ok="handlerOk"
    />
    <form-modal
      v-bind="edituserModal"
      v-if="edituserModal.visible"
      @cancel="
        () => {
          edituserModal.visible = false;
        }
      "
      @ok="handlerOk"
    />
  </page-container>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from 'vue';
import { Badge, message } from 'ant-design-vue';
import {
  ColumnHeightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';
import {
  create_remittance_set,
  delete_remittance_set,
  query_remittance_list,
  query_remittance_set_list,
  recover_remittance_set,
  update_remittance_set,
} from '@/api/cerp-model.ts';

import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useFullscreen } from '@/utils/hooks/useFullscreen';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import DragIcon from '@/components/table/drag-icon.vue';
import FormModal from '@/components/form-modal/form-modal.vue';
import { useStore } from 'vuex';
import { useForm } from 'ant-design-vue/es/form';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import { getDate } from '@/utils/function';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const statusMap = {
  true: { text: '正常', status: 'green' },
  false: { text: t('禁用'), status: 'red' },
};
const status_list = [
  { text: t('全部'), value: '' },
  { text: '正常', value: true },
  { text: t('禁用'), value: false },
];
const status = ['正常', '禁用'];

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
  },
  {
    title: '名称',
    dataIndex: 'remittance_name',
  },
  {
    title: '描述',
    dataIndex: 'description',
  },
  {
    title: '日期',
    dataIndex: 'date_received',
    content: (record: any) => {
      return getDate(record.date_received);
    },
  },
  {
    title: '总金额',
    dataIndex: 'total_amount',
    cellComponent: 'money',
  },
  {
    title: '银行流水',
    dataIndex: 'transaction__name',
  },
  {
    title: '状态',
    dataIndex: 'is_active',
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filters: [
      {
        text: status[0],
        value: true,
      },
      {
        text: status[1],
        value: false,
      },
    ],
  },
  {
    title: t('操作'),
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
  },
];
const baseUserColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    slots: { customRender: 'index' },
  },
  {
    title: '描述',
    dataIndex: 'remittance_set__remittance_name',
  },
  {
    title: '金额',
    dataIndex: 'remittance_set__total_amount',
    cellComponent: 'money',
  },
  {
    title: '总金额',
    dataIndex: 'amount',
    cellComponent: 'money',
  },
  {
    title: '状态',
    dataIndex: 'is_active',
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filters: [
      {
        text: status[0],
        value: true,
      },
      {
        text: status[1],
        value: false,
      },
    ],
  },
  {
    title: t('操作'),
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
  },
];

export default defineComponent({
  name: 'groupManage',
  setup() {
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const tableSize = reactive({ y: window.innerHeight - 420 });
    onMounted(() => {
      window.onresize = function () {
        tableSize.y = window.innerHeight - 420;
      };
    });
    const {
      state: columnState,
      dynamicColumns,
      dynamicColumnItems,
      handleColumnAllClick,
      handleColumnChange,
      reset,
      move,
    } = useTableDynamicColumns(baseColumns, true);
    const {
      state: userColumnState,
      dynamicColumns: userDynamicColumns,
      dynamicColumnItems: userDynamicColumnItems,
      handleColumnAllClick: userHandleColumnAllClick,
      handleColumnChange: userHandleColumnChange,
      reset: userReset,
      move: userMove,
    } = useTableDynamicColumns(baseUserColumns, true);
    const [elRef, screenState, { setFull, exitFull }] = useFullscreen();

    const search_modelRef = reactive({
      org_id: current_org.id,
      is_active: true,
    });
    const user_search_modelRef = reactive({
      org_id: current_org.id,
      is_active: true,
      remittance_set_id: '',
    });
    const search_config_options = {
      is_active: [
        { name: '全部', value: '' },
        { name: '正常', value: true },
        { name: '禁用', value: false },
      ],
    };
    const search_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'input',
          name: 'remittance_name',
          label: '名称',
          label_i18n: '名称',
          placeholder_i18n: '请填写名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写名称',
        },
        {
          type: 'remoteselect',
          name: 'transaction_id',
          label: '银行流水',
          label_i18n: '银行流水',
          placeholder_i18n: '请填写银行流水',
          disabled: false,
          placeholder: '请选择银行流水',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          modalType: 'cerp-banktransaction',
        },
        {
          type: 'select',
          name: 'is_active',
          label: '状态',
          label_i18n: '状态',
          mode: 'default',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择状态',
          datasource: 'is_active',
        },
      ],
      rules: {},
      model: search_modelRef,
    };
    const { validateInfos: searchValidateInfos } = useForm(search_modelRef, {});

    const user_search_config_options = {
      is_active: [
        { name: '全部', value: '' },
        { name: '正常', value: true },
        { name: '禁用', value: false },
      ],
    };
    const user_search_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        // {
        //   type: 'remoteselect',
        //   name: 'invoice_id',
        //   label: '请款单',
        //   disabled: false,
        //   placeholder: '请选择请款单',
        //   datasourceType: 'remote',
        //   mode: 'default',
        //   labelKey: 'invoice_no',
        //   allowClear: true,
        //   modalType: 'cerp-invoice',
        // },
        // {
        //   type: 'remoteselect',
        //   name: 'remittance_set_id',
        //   label: '汇款凭证',
        //   disabled: false,
        //   placeholder: '请选择汇款凭证',
        //   datasourceType: 'remote',
        //   mode: 'default',
        //   allowClear: true,
        //   modalType: 'cerp-remittanceset',
        // },
        {
          type: 'select',
          name: 'is_active',
          label: '状态',
          label_i18n: '状态',
          mode: 'default',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择状态',
          datasource: 'is_active',
        },
      ],
      rules: {},
      model: user_search_modelRef,
    };
    const { validateInfos: userSearchValidateInfos } = useForm(user_search_modelRef, {});
    // 汇款凭证 接口
    const { stripe, reload, setPageInfo, context: state } = useFetchData(
      query_remittance_set_list,
      {
        current: 1,
        pageSize: 20,
        tableSize: 'small', // 'default' | 'middle' | 'small'
        stripe: true,
        requestParams: {
          ...search_modelRef,
        },
      },
    );

    // watch(
    //   () => state.dataSource,
    //   () => {
    //     console.log('state', state);
    //     setUserPageInfo({
    //       current: 1,
    //       ...user_search_modelRef,
    //       remittance_set_id: (state.dataSource[0] as any).id,
    //     });
    //     userReload();
    //   },
    // );
    // 汇款凭证项 接口
    const {
      stripe: userStripe,
      reload: userReload,
      setPageInfo: setUserPageInfo,
      context: userState,
    } = useFetchData(query_remittance_list, {
      current: 1,
      pageSize: 20,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...user_search_modelRef,
      },
    });
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...filters,
        ...search_modelRef,
      });

      reload();
    };

    const search = () => {
      setPageInfo({
        current: 1,
        ...search_modelRef,
      });
      reload();
    };
    const handleUserTableChange = (
      { current: userCurrent, pageSize: userPageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setUserPageInfo({
        userCurrent: userCurrent ? userCurrent : userState.current,
        userPageSize: userPageSize ? userPageSize : userState.pageSize,
        ...filters,
        ...user_search_modelRef,
      });

      userReload();
    };

    // 汇款凭证项 【查询】
    const userSearch = () => {
      setUserPageInfo({
        current: 1,
        ...user_search_modelRef,
      });
      userReload();
    };
    // edit
    const edit_model_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'vertical',
      },
      fields: [
        {
          type: 'input',
          name: 'remittance_name',
          label: '名称',
          label_i18n: '名称',
          placeholder_i18n: '请填写名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填写名称',
        },
        {
          type: 'textarea',
          name: 'description',
          label: '描述',
          label_i18n: '描述',
          placeholder_i18n: '请填描述',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填描述',
        },
        {
          type: 'remoteselect',
          name: 'recuitment_company_id',
          label: '顾问公司',
          label_i18n: '顾问公司',
          placeholder_i18n: '请选择顾问公司',
          disabled: false,
          placeholder: '请选择顾问公司',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          labelKey: 'company_name',
          valueKey: 'id',
          modalType: 'baseinfo-recuitmentcompany',
        },
        {
          type: 'money',
          name: 'total_amount',
          label: '总金额',
          label_i18n: '总金额',
          placeholder_i18n: '请填写总金额',
          disabled: false,
          allowClear: true,
          inputType: 'number',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填总金额',
        },
        {
          type: 'remoteselect',
          name: 'transaction_id',
          label: '银行流水',
          label_i18n: '银行流水',
          placeholder_i18n: '请填写银行流水',
          disabled: false,
          placeholder: '请选择银行流水',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          modalType: 'cerp-banktransaction',
        },
      ],
      rules: {
        remittance_name: [{ required: true, message: '名称必须填写' }],
        description: [{ required: true, message: '描述必须填写' }],
      },
      model: {
        id: null,
        transaction_id: null,
        remittance_name: '',
        description: '',
        recuitment_company_id: null,
        total_amount: 0,
      },
    };
    const edit_model_config_options = {};
    const editModal = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: edit_model_config,
      options: edit_model_config_options,
    });
    let currentRecord = reactive({ name: '' });
    const handleOpenEdit = (record: any) => {
      editModal.visible = true;
      editModal.title = '修改汇款凭证';
      editModal.form.model = { ...record };
      currentRecord = record;
    };

    const handleDelete = (record: any) => {
      delete_remittance_set({ remittance_set_id: record.id, org_id: current_org.id }).then(res => {
        message.success(t('删除成功'));
        Object.assign(record, res);
      });
    };

    const handleRecover = (record: any) => {
      recover_remittance_set({ remittance_set_id: record.id, org_id: current_org.id }).then(res => {
        message.success(t('恢复成功'));
        Object.assign(record, res);
      });
    };

    const handlerOk = (data: any) => {
      if (!editModal.form.model.id) {
        create_remittance_set({ ...data, org_id: current_org.id }).then(() => {
          message.success('新增成功');
          reload();
        });
      } else {
        update_remittance_set({
          ...data,
          org_id: current_org.id,
          remittance_set_id: editModal.form.model.id,
        }).then((res: any) => {
          // currentRecord.name = res.name;
          Object.assign(currentRecord, res);
          message.success(t('修改成功'));
        });
      }
      editModal.visible = false;
      // reload();
    };

    const edit_usermodel_config = {
      settings: {
        name: '表单名称',
        labelAlign: 'right',
        layout: 'vertical',
      },
      fields: [
        {
          type: 'remoteselect',
          name: 'invoice_id',
          label: '请款单',
          label_i18n: '请款单',
          placeholder_i18n: '请款单',
          disabled: false,
          placeholder: '请选择请款单',
          datasourceType: 'remote',
          mode: 'default',
          labelKey: 'invoice_no',
          allowClear: true,
          modalType: 'cerp-invoice',
        },
        {
          type: 'remoteselect',
          name: 'remittance_set_id',
          label: '汇款凭证',
          label_i18n: '汇款凭证',
          placeholder_i18n: '请选择汇款凭证',
          disabled: false,
          placeholder: '请选择汇款凭证',
          datasourceType: 'remote',
          mode: 'default',
          labelKey: 'remittance_name',
          allowClear: true,
          modalType: 'cerp-remittanceset',
        },
        {
          type: 'textarea',
          name: 'description',
          label: '描述',
          label_i18n: '描述',
          placeholder_i18n: '请填描述',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填描述',
        },
        {
          type: 'money',
          name: 'amount',
          label: '金额',
          label_i18n: '金额',
          placeholder_i18n: '请填总金额',
          disabled: false,
          allowClear: true,
          inputType: 'number',
          maxlength: 100,
          defaultValue: '',
          placeholder: '请填总金额',
        },
        {
          type: 'remoteselect',
          name: 'transaction_id',
          label: '银行流水',
          label_i18n: '银行流水',
          placeholder_i18n: '请填写银行流水',
          disabled: false,
          placeholder: '请选择银行流水',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          modalType: 'cerp-banktransaction',
        },
      ],
      rules: {
        invoice_id: [{ required: true, message: '请选择请款单' }],
      },
      model: {
        id: null,
        remittance_set_id: null,
        invoice_id: null,
        description: '',
        transaction_id: null,
        amount: '',
      },
    };
    const edit_usermodel_config_options = {};
    const edituserModal = reactive({
      visible: false,
      title: '',
      aim_org_id: '',
      form: edit_usermodel_config,
      options: edit_usermodel_config_options,
    });
    let currentRecord1 = reactive({ name: '' });
    const handleuserOpenEdit = (record: any) => {
      editModal.visible = true;
      editModal.title = '修改汇款凭证项';
      editModal.form.model = { ...record };
      currentRecord1 = record;
    };
    const setRowClassName = (record: any, index: number) => {
      // let rowColor = index % 2 === 0 ? 'ant-pro-table-row-striped' : ''; //判断单双行，赋予不同样式
      return record === state.currentRow ? 'clickRowStyl1' : ''; //赋予点击行样式
    };

    // 接口返回时间，格式优化
    const getDateTime = (time: any) => {
      if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };

    return {
      statusMap,
      tableSize,
      state,
      status_list,
      stripe,
      columnState,
      dynamicColumns,
      dynamicColumnItems,

      handlerOk,
      getDateTime,
      currentRecord,
      search_modelRef,
      searchValidateInfos,
      // fullscreen
      elRef,
      screenState,
      setFull,
      exitFull,

      handleTableChange,
      search,

      handleColumnChange,
      handleColumnAllClick,
      reset,
      move,
      current_org,
      message,
      // edit
      edit_model_config,
      edit_model_config_options,
      editModal,
      handleuserOpenEdit,
      handleOpenEdit,
      handleDelete,
      handleRecover,
      reload,
      search_config,
      search_config_options,
      setRowClassName,
      //user_search
      // userElRef,
      // userScreenState,
      // setUserFull,
      // exitUserFull,
      user_search_modelRef,
      userColumnState,
      userDynamicColumns,
      userDynamicColumnItems,
      userHandleColumnAllClick,
      userHandleColumnChange,
      userReset,
      userMove,

      handleUserTableChange,
      userSearch,
      edituserModal,
      setUserPageInfo,
      userStripe,
      userState,
      userReload,
      user_search_config,
      user_search_config_options,
      userSearchValidateInfos,
    };
  },
  components: {
    SearchRender,
    DragIcon,
    PlusOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    SettingOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    Draggable,
    DragContainer,
    [Badge.name]: Badge,
    FormModal,
  },
});
</script>
<style lang="less">
.clickRowStyl1 {
  background-color: #e38eff !important;
}
</style>
